(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/maintenance-view/assets/javascripts/maintenance-view.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/maintenance-view/assets/javascripts/maintenance-view.js');
"use strict";

const {
  header,
  body
} = svs.oddset_kambi.data.maintenanceConfig;
const MaintenanceView = _ref => {
  let {
    maintenanceText
  } = _ref;
  return React.createElement("div", {
    className: "oddset-maintenance-view"
  }, React.createElement("div", {
    className: "oddset-maintenance-view__left"
  }), React.createElement("div", {
    className: "oddset-maintenance-view__right"
  }, React.createElement("h2", null, header), React.createElement("h4", null, maintenanceText ? maintenanceText : body), React.createElement("p", null, "Spel fr\xE5n Svenska Spel & Casino AB")));
};
setGlobal('svs.oddset_kambi.components.maintenance_view.MaintenanceView', MaintenanceView);

 })(window);